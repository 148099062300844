import CorrectImageOrientation from './correct-image-orientation.js';
import API from './api.js';
import extractHumanFeatures from './extract-human-features.js';
import renderPetswitch from './render-petswitch.js';
import helpers from './helpers.js';
import DOM from './dom.js';

export default {

    humanFace: async function (event,
        uiImageElements,
        selectedPet,
        outputCanvas,
        correctedPetImageData,
        petLandmarks) {

        return new Promise((resolve, reject) => {

            let correctImageOrientation = new CorrectImageOrientation();
            correctImageOrientation.checkImageFormat(event, async (data) => {

                let correctedImageData = data;

                helpers.setThumbnail('.edit-human-button', data.canvas);

                API.getHumanFaceLandmarks(correctedImageData.corrected).then((humanLandmarks) => {

                    extractHumanFeatures.beginRender(
                        humanLandmarks,
                        correctedImageData.canvas,
                        uiImageElements,
                        (humanFeatures) => {

                            if (selectedPet != 'USER_SELECTED') {
                                // paint these masks onto our final canvas

                                renderPetswitch.outputFinalImage(
                                    selectedPet,
                                    humanFeatures,
                                    outputCanvas
                                );

                            } else {

                                if (petLandmarks.userSelection && petLandmarks.userSelection.fromUserSelection) {
                                    petLandmarks.prediction = {
                                        fromPreviousUserSelection: true,
                                        output: petLandmarks.userSelection.output
                                    }
                                }

                                // paint these masks onto our final canvas
                                renderPetswitch.outputFinalImage(
                                    selectedPet,
                                    humanFeatures,
                                    outputCanvas,
                                    uiImageElements,
                                    correctedPetImageData.canvas,
                                    petLandmarks.prediction
                                );

                            }

                            resolve(humanFeatures);

                        });

                }).catch((error) => {
                    reject(Error('error getting human landmarks:', error))
                });

            });

        });

    },

    petFace: function (event,
        selectedPet,
        humanFeatures,
        outputCanvas,
        uiImageElements) {

        return new Promise((resolve, reject) => {

            let correctImageOrientation = new CorrectImageOrientation();
            correctImageOrientation.checkImageFormat(event, (data) => {

                DOM.petSelectionPanel('hide');
                DOM.startLoadingSequence();
                DOM.outputCanvas('hide');
                DOM.loader('show');
                DOM.clearPetSelection();

                let correctedPetImageData = data;
                helpers.setThumbnail('.toggle-pets-button', data.canvas);
                API.getPetFaceLandmarks({
                    image: correctedPetImageData.corrected,
                    imageWidth: correctedPetImageData.canvas.width,
                    imageHeight: correctedPetImageData.canvas.height

                }).then((petLandmarks) => {

                    renderPetswitch.outputFinalImage(
                        selectedPet,
                        humanFeatures,
                        outputCanvas,
                        uiImageElements,
                        correctedPetImageData.canvas,
                        petLandmarks.prediction,
                        (output) => {
                            petLandmarks.userSelection = output;
                        }
                    );

                    let result = {
                        correctedPetImageData: correctedPetImageData,
                        petLandmarks: petLandmarks
                    }

                    resolve(result);

                }).catch((error) => {
                    reject(Error('error getting pet landmarks:', error))
                });

            })

        });

    }

}