// Import the functions you need from the SDKs you need
import { initializeApp } from "@firebase/app";
// import { getFunctions, httpsCallable, connectFunctionsEmulator } from "@firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDEGj9-a-wsTsAakElMzigS8ujtZz8gQk8",
    authDomain: "petswitch-d2764.firebaseapp.com",
    projectId: "petswitch-d2764",
    storageBucket: "petswitch-d2764.appspot.com",
    messagingSenderId: "971260705328",
    appId: "1:971260705328:web:2bf5ab52b32754a7e0cbf5",
    measurementId: "G-X97VEXSSHG"
};
 
export const app = initializeApp(firebaseConfig);

// console.log('firebase init');
