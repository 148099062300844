import helpers from './helpers.js';
import constants from './constants.js';

// this class creates a temp img element and populates
// a canvas element with the supplied image
// this fixes image orientation issues and avoids dealing with EXIF data

export class CorrectImageOrientation {

    checkImageFormat(event, callback) {

        if (event.target.files && event.target.files.length > 0) {
            let file = event.target.files[0];
            if (file.type == 'image/jpeg' // only in this case rotation is needed
                || file.type == 'image/bmp'
                || file.type == 'image/gif'
                || file.type == 'image/png'
                || file.type == 'image/tiff') {
                this.correctImage(file, (data) => {
                    callback(data);
                })
            } else {
                console.log('Incorrect image format.')
                return null;
            }
        }
    }

    correctImage(file, callback) {
        this.loadImage(file, (image) => {

            // render our image onto a canvas and resize witin our specs

            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');

            let resized = helpers.calculateAspectRatioFit(
                image.width,
                image.height,
                constants.MAX_WIDTH,
                constants.MAX_HEIGHT);

            canvas.width = resized.width;
            canvas.height = resized.height;

            ctx.drawImage(image, 0, 0, resized.width, resized.height);

            try {
                // create a base64 version of our image to send to the cloud vision API
                let correctedData = canvas.toDataURL('image/jpeg', 0.7);
                let data = {
                    corrected:correctedData, 
                    canvas: canvas
                };
                // console.log(correctedData);
                callback(data);
            } catch (e) {
                console.log('Save image error.');
                console.log(e);
            }
        });
    }

    loadImage(file, callback) {

        let image = document.createElement('img');
        image.onload = () => {
            URL.revokeObjectURL(image.src);
            callback(image);
        };
        image.onerror = () => {
            URL.revokeObjectURL(image.src);
            console.log('Incorrect image format.');
        };
        image.src = URL.createObjectURL(file);
    }

}

module.exports = CorrectImageOrientation;