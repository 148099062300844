import constants from './constants.js';

export default {

    getHumanFaceLandmarks: async function(data) {
        
        // console.log(data.substring(0, 50) + '...');

        const headers = new Headers();
        headers.append("Content-Type", "application/json");

        const body = JSON.stringify({
            "image": data
        });

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: body,
            redirect: 'follow'
        };

        const response = await fetch(constants.HUMAN_FACE_LANDMARKS_ENDPOINT, requestOptions);
        return response.json();

    },

    getPetFaceLandmarks: async function(data) {
        
        // console.log(data.image.substring(0, 50) + '...');

        const headers = new Headers();
        headers.append("Content-Type", "application/json");

        const body = JSON.stringify({
            "image": data.image,
            "imageWidth": data.imageWidth,
            "imageHeight": data.imageHeight,
        });

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: body,
            redirect: 'follow'
        };

        const response = await fetch(constants.PET_FACE_LANDMARKS_ENDPOINT, requestOptions);
        return response.json();

    } 
}