export default {

    elements: {
        container: document.createElement('div'),
        instructions: document.createElement('p'),
        shrek: document.createElement('img')
    },

    add: function (type, stageParent) {

        // let instructions = document.createTextNode(`I couldn't see a ${type} in this image! Drag the pointers to the eyes and mouth.`);
        // this.elements.instructions.appendChild(instructions);
        this.elements.container.setAttribute('class', 'container');
        this.elements.shrek.src = new URL('../assets/images/shrek.gif?as=webp&width=100', import.meta.url);

        this.elements.instructions.innerText = `I couldn't see a ${type} in this image! Drag the pointers to the eyes and mouth.`
        this.elements.container.appendChild(this.elements.instructions);

        this.elements.shrek.onload = () => {
            this.elements.container.appendChild(this.elements.shrek);
            // console.log('loaded shrek');
            // console.log(this.elements.shrek);
    
        };
        this.elements.shrek.onerror = (e) => {
            // URL.revokeObjectURL(image.src);
            console.log('error:', e);
        };

        // console.log(stageParent);
        stageParent.appendChild(this.elements.container);
   
    }

}