import pets from './pets.js';
import petImages from 'url:../assets/pets/*.webp';
import ManualLandarkSelect from './manual-landmark-select.js';
import helpers from './helpers.js';
import DOM from './dom.js';
import share from './share.js';

export default {

    outputFinalImage: function (
        sourcePet,
        renderedImageMasks,
        outputCanvas,
        uiImageElements,
        userPetCanvas = null,
        userPetLandmarks = null,
        callback) {

        // console.log(sourcePet);

        if (sourcePet == 'USER_SELECTED') {

            if (userPetLandmarks.success) {

                this.renderFromAPI(
                    outputCanvas,
                    userPetCanvas,
                    userPetLandmarks,
                    renderedImageMasks,
                    sourcePet);

            } else if (userPetLandmarks.fromPreviousUserSelection) {

                this.renderFromPreviousSelection(
                    outputCanvas,
                    userPetLandmarks.output.savedPet,
                    renderedImageMasks,
                    userPetLandmarks.output,
                    sourcePet);

            } else {

                this.renderFromUserLandmarkSelection(
                    outputCanvas,
                    userPetCanvas,
                    renderedImageMasks,
                    uiImageElements,
                    sourcePet,
                    callback);
            }

            return;

        }

        this.renderFromPetswitchFamily(outputCanvas, renderedImageMasks, sourcePet);


    },

    renderFromPreviousSelection: function (outputCanvas, userPetCanvas, renderedImageMasks, userPetLandmarks, sourcePet) {
        let ctx = outputCanvas.getContext('2d');

        outputCanvas.width = userPetCanvas.width;
        outputCanvas.height = userPetCanvas.height;

        ctx.drawImage(userPetCanvas, 0, 0);

        for (let i = 0; i < renderedImageMasks.length; i++) {
            // for (let i in renderedImageMasks) {
            this.drawMaskOnPet(renderedImageMasks[i], outputCanvas, sourcePet, userPetLandmarks);
        }

        helpers.trimCanvas(outputCanvas);


        DOM.outputCanvas('show');
        DOM.loader('hide');

        share.setDownloadPath(outputCanvas);
    },


    renderFromUserLandmarkSelection: function (
        outputCanvas,
        userPetCanvas,
        renderedImageMasks,
        uiImageElements,
        sourcePet,
        callback) {
        // console.log('manually selecting pet landmarks')

        let ctx = outputCanvas.getContext('2d');

        let manualLandmarkSelect = new ManualLandarkSelect();

        manualLandmarkSelect.startSelection(
            userPetCanvas,
            uiImageElements.EYE_SELECTOR,
            uiImageElements.MOUTH_SELECTOR,
            'pet',
            (output) => {

                outputCanvas.width = output.canvas.width;
                outputCanvas.height = output.canvas.height;
                ctx.drawImage(output.canvas, 0, 0);

                output['savedPet'] = this.savePet(outputCanvas);

                for (let i = 0; i < renderedImageMasks.length; i++) {

                    this.drawMaskOnPet(renderedImageMasks[i], outputCanvas, sourcePet, output);
                }

                helpers.trimCanvas(outputCanvas);

                DOM.outputCanvas('show');
                DOM.loader('hide');
                DOM.petSelectionPanel('hide');

                callback({
                    fromUserSelection: true,
                    output: output
                });

                share.setDownloadPath(outputCanvas);
                // // URL.revokeObjectURL(image.src);
                // console.log('rendered masks to final image');


            });
    },

    savePet: function (outputCanvas) {

        let savedPet = document.createElement('canvas');
        let ctx = savedPet.getContext('2d');
        savedPet.width = outputCanvas.width;
        savedPet.height = outputCanvas.height;
        ctx.drawImage(outputCanvas, 0, 0);

        return savedPet;
    },

    renderFromPetswitchFamily: function (outputCanvas, renderedImageMasks, sourcePet) {

        DOM.outputCanvas('hide');
        DOM.loader('show');

        let ctx = outputCanvas.getContext('2d');

        // chose from the petswitch family, load image
        let image = document.createElement('img');

        image.onload = () => {
            outputCanvas.width = image.width;
            outputCanvas.height = image.height;

            ctx.drawImage(image, 0, 0);

            for (let i = 0; i < renderedImageMasks.length; i++) {
                // for (let i in renderedImageMasks) {
                this.drawMaskOnPet(renderedImageMasks[i], outputCanvas, sourcePet);
            }

            DOM.outputCanvas('show');
            DOM.loader('hide');
            

            share.setDownloadPath(outputCanvas);
            // URL.revokeObjectURL(image.src);
            // console.log('rendered masks to final image');


        };
        image.onerror = (e) => {
            // URL.revokeObjectURL(image.src);
            console.log('error:', e);
        };

        image.src = new URL(`${petImages[sourcePet]}`, import.meta.url);

        //drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight)

    },

    renderFromAPI: function (outputCanvas, userPetCanvas, userPetLandmarks, renderedImageMasks, sourcePet) {

        // console.log('rendering from pet API');

        let ctx = outputCanvas.getContext('2d');

        outputCanvas.width = userPetCanvas.width;
        outputCanvas.height = userPetCanvas.height;

        ctx.drawImage(userPetCanvas, 0, 0);

        for (let i = 0; i < renderedImageMasks.length; i++) {
            // for (let i in renderedImageMasks) {
            this.drawMaskOnPet(renderedImageMasks[i], outputCanvas, sourcePet, userPetLandmarks.data);
        }

        DOM.outputCanvas('show');
        DOM.petSelectionPanel('hide');
        DOM.loader('hide');

        share.setDownloadPath(outputCanvas);
        // URL.revokeObjectURL(image.src);
        // console.log('rendered masks to final image');
    },

    drawMaskOnPet: function (imageMask, outputCanvas, sourcePet, userPetLandmarks = null) {

        let ctx = outputCanvas.getContext('2d');
        let landmarks;
        let width;

        if (sourcePet == 'USER_SELECTED') {
            landmarks = userPetLandmarks;
        } else {
            landmarks = pets[sourcePet];
        }
        // console.log(landmarks);

        if (imageMask.id != 'mouth') {
            width = Math.round(1.6 * landmarks.occularWidth);
        } else {
            width = Math.round(2 * landmarks.occularWidth);
        }

        let height = width;
        let rollAngle = helpers.getRollAngle(
            landmarks.coordinates.eyeLeft.position.x,
            landmarks.coordinates.eyeLeft.position.y,
            landmarks.coordinates.eyeRight.position.x,
            landmarks.coordinates.eyeRight.position.y);

        let rotatedMask = helpers.rotateCanvas(imageMask.maskOutput, -rollAngle);

        ctx.drawImage(
            rotatedMask,
            0,
            0,
            Math.round(imageMask.width),
            Math.round(imageMask.height),
            Math.round(landmarks.coordinates[imageMask.id].position.x - width / 2),
            Math.round(landmarks.coordinates[imageMask.id].position.y - height / 2),
            Math.round(width),
            Math.round(height)
        )

        rotatedMask = null;

    }
}