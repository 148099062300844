
export default {
    confirmAddImage: function (element, type) {

        let tick = document.querySelector(`.get-${type}`).querySelector('.tick');
        tick.classList.add('scaled');

        if (type == 'pet') {
            element.parentNode.classList.remove('plus-dark');
        }

        element.parentNode.classList.remove('plus');
        element.parentNode.classList.add('border');
    },

    addOutline: function (element) {

        this.clearPetSelection();
        element.querySelector('img').classList.add('outline');

    },

    clearPetSelection: function() {
        document.querySelectorAll('.pet img').forEach((el) => el.classList.remove('outline'));
    },

    notice: function(mode) {
        if (mode === 'show') {
            document.querySelector('.notice').classList.add('visible');
        } else {
            document.querySelector('.notice').classList.remove('visible');
        }
    },

    startLoadingSequence: function () {

        let chooseContainer = document.querySelector('.choose-container');

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });

        chooseContainer.classList.add('fade-out');

        setTimeout(() => this.setEditMode(chooseContainer), 400);

    },

    setEditMode: function(chooseContainer) {
        chooseContainer.classList.add('edit-mode');
        // chooseContainer.classList.add('edit-mode');
    },

    outputCanvas: function(mode) {
        if (mode === 'show') {
            document.querySelector('.output-all-container').classList.add('visible');
        } else {
            document.querySelector('.output-all-container').classList.remove('visible');
        }
    },

    loader: function(mode) {
        if (mode === 'show') {
            document.querySelector('.loader').classList.add('visible');
        } else {
            document.querySelector('.loader').classList.remove('visible');
        }
        
    },

    petSelectionPanel: function(mode) {

        if (mode === 'show') {
            document.querySelector('header').classList.add('shrink');
            document.querySelector('.output-all-container').classList.add('shrink');
            document.querySelector('.choose-container').classList.add('visible');
            document.querySelector('.choose-container').classList.remove('fade-out');
            document.querySelector('.overlay').classList.add('visible');

        } else {
            document.querySelector('header').classList.remove('shrink');
            document.querySelector('.output-all-container').classList.remove('shrink');
            document.querySelector('.choose-container').classList.remove('visible');
            document.querySelector('.overlay').classList.remove('visible');
            // document.querySelector('.choose-container').classList.remove('fade-out');

        }

    }
}