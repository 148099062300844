import constants from "./constants";

export default {

    calculateAspectRatioFit: function (srcWidth, srcHeight, maxWidth, maxHeight) {

        // resize our image into the specs we need

        let ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

        return { width: srcWidth * ratio, height: srcHeight * ratio };
    },

    radians: function (degrees) {

        // convert our rotation information into radians for canvas manipulation

        return degrees * Math.PI / 180;
    },

    rotateCoordinate(cx, cy, x, y, angle) {

        // rotate the landmarks provided by the cloud vision API if the face in the supplied
        // image isn't vertically aligned

        var radians = (Math.PI / 180) * angle,
            cos = Math.cos(radians),
            sin = Math.sin(radians),
            nx = (cos * (x - cx)) + (sin * (y - cy)) + cx,
            ny = (cos * (y - cy)) - (sin * (x - cx)) + cy;
        return [nx, ny];
    },

    renderLandmarks(canvas, landmarks) {
        // draw given landmarks onto a canvas

        landmarks = landmarks.landmarks;
        radius = 2;

        let ctx = canvas.getContext('2d');
        ctx.beginPath();
        ctx.fillStyle = '#97ec4d';

        for (let i = 0; i < landmarks.length; i++) {

            ctx.moveTo(landmarks[i].position.x, landmarks[i].position.y);
            ctx.arc(landmarks[i].position.x, landmarks[i].position.y, radius, 0, 2 * Math.PI, false);
        }

        ctx.fill();
    },

    isTouchDevice() {
        return (('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            (navigator.msMaxTouchPoints > 0));
    },


    rotateCanvas: function (workingCanvas, rollAngle) {

        let canvas = document.createElement('canvas');
        canvas.width = workingCanvas.width;
        canvas.height = workingCanvas.height;
        let ctx = canvas.getContext('2d');

        ctx.save(); //saves the state of canvas
        ctx.clearRect(0, 0, workingCanvas.width, workingCanvas.height); //clear the canvas
        ctx.translate(
            workingCanvas.width / 2,
            workingCanvas.height / 2); //let's translate
        ctx.rotate(Math.PI / 180 * -rollAngle); //increment the angle and rotate the image 
        ctx.translate(
            -(workingCanvas.width / 2),
            -(workingCanvas.height / 2)); //let's translate
        ctx.drawImage(
            workingCanvas,
            workingCanvas.width / 2 - workingCanvas.width / 2,
            workingCanvas.height / 2 - workingCanvas.height / 2,
            workingCanvas.width,
            workingCanvas.height); //draw the image ;)
        ctx.restore(); //restore the state of canvas

        return canvas;

    },

    getRollAngle(cx, cy, ex, ey) {
        var dy = ey - cy;
        var dx = ex - cx;
        var theta = Math.atan2(dy, dx); // range (-PI, PI]
        theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
        //if (theta < 0) theta = 360 + theta; // range [0, 360)
        return theta;
    },

    // Trim a canvas.
    // 
    // @author Arjan Haverkamp(arjan at avoid dot org)
    // @param { canvas } canvas A canvas element to trim.This element will be trimmed(reference)
    // @param { int } threshold Alpha threshold.Allows for trimming semi - opaque pixels too.Range: 0 - 255
    // @returns { Object } Width and height of trimmed canvcas and left - top coordinate of trimmed area.Example: { width: 400, height: 300, x: 65, y: 104 }
    trimCanvas(canvas, threshold = 0) {

        const ctx = canvas.getContext('2d'),
            w = canvas.width, h = canvas.height,
            imageData = ctx.getImageData(0, 0, w, h),
            tlCorner = { x: w + 1, y: h + 1 },
            brCorner = { x: -1, y: -1 };

        for (let y = 0; y < h; y++) {
            for (let x = 0; x < w; x++) {
                if (imageData.data[((y * w + x) * 4) + 3] > threshold) {
                    tlCorner.x = Math.min(x, tlCorner.x);
                    tlCorner.y = Math.min(y, tlCorner.y);
                    brCorner.x = Math.max(x, brCorner.x);
                    brCorner.y = Math.max(y, brCorner.y);
                }
            }
        }

        const cut = ctx.getImageData(tlCorner.x, tlCorner.y, brCorner.x - tlCorner.x, brCorner.y - tlCorner.y);

        canvas.width = brCorner.x - tlCorner.x;
        canvas.height = brCorner.y - tlCorner.y;

        ctx.putImageData(cut, 0, 0);

        return { width: canvas.width, height: canvas.height, x: tlCorner.x, y: tlCorner.y };
    },

    setThumbnail(selector, element, gradient = true) {

        if (element.nodeName && element.nodeName.toLowerCase() === 'div') {
            document.querySelector(selector).style.backgroundImage = `
            linear-gradient(to bottom, 
                rgba(0, 0, 0, 0.6), 
                rgba(0, 0, 0, 0)),
                url('${element.querySelector('img').src}')`;
            return
        }

        let thumbnail = document.createElement('canvas');
        let ctx = thumbnail.getContext('2d');

        let resized = this.calculateAspectRatioFit(
            element.width,
            element.height,
            constants.THUMBNAIL_MAX_WIDTH,
            constants.THUMBNAIL_MAX_HEIGHT);

        thumbnail.width = resized.width;
        thumbnail.height = resized.height;

        ctx.drawImage(element, 0, 0, resized.width, resized.height);

        let output = thumbnail.toDataURL('image/jpeg', 0.7);

        thumbnail = null;

        if (!gradient) {
            document.querySelector(selector).style.backgroundImage = `url('${output}')`;
            return;
        }

        document.querySelector(selector).style.backgroundImage = `
        linear-gradient(to bottom, 
            rgba(0, 0, 0, 0.6), 
            rgba(0, 0, 0, 0)),
            url('${output}')`;

    }
}