import ManualLandarkSelect from './manual-landmark-select.js';
import helpers from './helpers.js';

export default {

    beginRender: async function (landmarks, workingCanvas, uiImageElements, callback) {

        if (landmarks.length > 0) {

            return this.processCloudVisionLandmarks(landmarks, workingCanvas, uiImageElements, callback);

        } else if (landmarks.length == 0) {

            return this.processUserSelectionLandmarks(workingCanvas, uiImageElements, callback);

        }

    },

    processCloudVisionLandmarks: function (landmarks, workingCanvas, uiImageElements, callback) {

        // console.log('getting landmarks from cloud vision api');

        // get the data we need from the cloud vision API
        // update our landmark coordinates so they're rotated correctly

        landmarks = this.rotateLandmarks(workingCanvas, landmarks);

        // get the dimensions and coordinates of our masks

        let masks = this.getMaskCoordinatesFromCloudVision(landmarks, uiImageElements);

        // create a temporary canvas to render the rotated face

        let rotatedFaceCanvas = helpers.rotateCanvas(workingCanvas, landmarks.rollAngle);

        // render our rotated face image to the masks
        // to avoid features being overlapped, each feature is rendered to 
        // a seperate temporary canvas

        let renderedImageMasks = this.renderImageMasks(masks, workingCanvas, rotatedFaceCanvas);

        // dispose our canvases

        workingCanvas = null;
        rotatedFaceCanvas = null;

        callback(renderedImageMasks);

    },

    processUserSelectionLandmarks: function (workingCanvas, uiImageElements, callback) {

        // console.log('getting landmarks from user selection');

        let manualLandmarkSelect = new ManualLandarkSelect();

        // user will need to manually select eye and mouth coordinates
        manualLandmarkSelect.startSelection(
            workingCanvas,
            uiImageElements.EYE_SELECTOR,
            uiImageElements.MOUTH_SELECTOR,
            'human',
            (output) => {

                // document.body.append(output.canvas);

                let landmarks = this.rotateUserLandmarks(output.canvas, output);

                let masks = this.getMaskCoordinatesFromUserSelection(landmarks.coordinates, uiImageElements);

                let rotatedFaceCanvas = helpers.rotateCanvas(output.canvas, landmarks.rollAngle);

                let renderedImageMasks = this.renderImageMasks(masks, workingCanvas, rotatedFaceCanvas);

                workingCanvas = null;
                rotatedFaceCanvas = null;

                // draw our masked elements onto a new canvas with a pet on it
                callback(renderedImageMasks);

            });

    },

    rotateLandmarks: function (workingCanvas, landmarks) {

        landmarks = landmarks[0];

        let rollAngle = landmarks.rollAngle;

        for (let i = 0; i < landmarks.landmarks.length; i++) {

            let rotated = helpers.rotateCoordinate(
                workingCanvas.width / 2,
                workingCanvas.height / 2,
                landmarks.landmarks[i].position.x,
                landmarks.landmarks[i].position.y,
                rollAngle)

            landmarks.landmarks[i].position.x = rotated[0];
            landmarks.landmarks[i].position.y = rotated[1];

        }

        return landmarks;

    },

    rotateUserLandmarks: function (workingCanvas, landmarks) {

        let rollAngle = landmarks.rollAngle;

        for (const key of Object.keys(landmarks.coordinates)) {
            let rotated = helpers.rotateCoordinate(
                workingCanvas.width / 2,
                workingCanvas.height / 2,
                landmarks.coordinates[key].position.x,
                landmarks.coordinates[key].position.y,
                rollAngle)

            landmarks.coordinates[key].position.x = rotated[0];
            landmarks.coordinates[key].position.y = rotated[1];

        }
        return landmarks;

    },

    getMaskCoordinatesFromCloudVision: function (landmarks, uiImageElements) {
        // define the features of the face we need as returned from the cloud vision API

        landmarks = landmarks.landmarks;

        let LEFT_EYE_TOP_BOUNDARY = landmarks[16];
        let LEFT_EYE_RIGHT_CORNER = landmarks[17];
        let LEFT_EYE_BOTTOM_BOUNDARY = landmarks[18];
        let LEFT_EYE_LEFT_CORNER = landmarks[19];

        let RIGHT_EYE_TOP_BOUNDARY = landmarks[20];
        let RIGHT_EYE_RIGHT_CORNER = landmarks[21];
        let RIGHT_EYE_BOTTOM_BOUNDARY = landmarks[22];
        let RIGHT_EYE_LEFT_CORNER = landmarks[23];

        let UPPER_LIP = landmarks[8];
        let LOWER_LIP = landmarks[9];
        let MOUTH_LEFT = landmarks[10];
        let MOUTH_RIGHT = landmarks[11];

        let LEFT_EYE_WIDTH = LEFT_EYE_RIGHT_CORNER.position.x - LEFT_EYE_LEFT_CORNER.position.x;
        let LEFT_EYE_HEIGHT = LEFT_EYE_BOTTOM_BOUNDARY.position.y - LEFT_EYE_TOP_BOUNDARY.position.y;

        let RIGHT_EYE_WIDTH = RIGHT_EYE_RIGHT_CORNER.position.x - RIGHT_EYE_LEFT_CORNER.position.x;
        let RIGHT_EYE_HEIGHT = RIGHT_EYE_BOTTOM_BOUNDARY.position.y - RIGHT_EYE_TOP_BOUNDARY.position.y;

        let MOUTH_HEIGHT = LOWER_LIP.position.y - UPPER_LIP.position.y;
        let MOUTH_WIDTH = MOUTH_RIGHT.position.x - MOUTH_LEFT.position.x;

        let LEFT_EYE_CENTER = landmarks[0];
        let RIGHT_EYE_CENTER = landmarks[1];
        let MOUTH_CENTER = landmarks[12];

        // use the landmarks provided by the cloud vision API to position our image clipping masks

        return [{
            'maskImage': uiImageElements.EYE_MASK,
            'center': LEFT_EYE_CENTER,
            'width': LEFT_EYE_WIDTH,
            'height': LEFT_EYE_HEIGHT,
            'id': 'eyeLeft'
        }, {
            'maskImage': uiImageElements.EYE_MASK,
            'center': RIGHT_EYE_CENTER,
            'width': RIGHT_EYE_WIDTH,
            'height': RIGHT_EYE_HEIGHT,
            'id': 'eyeRight'
        }, {
            'maskImage': uiImageElements.MOUTH_MASK,
            'center': MOUTH_CENTER,
            'width': MOUTH_WIDTH,
            'height': MOUTH_HEIGHT,
            'id': 'mouth'
        }];

    },

    getMaskCoordinatesFromUserSelection: function (coordinates, uiImageElements) {

        // we'll guess the size of the eyes and mouth based on the distance between the eyes (occular width)
        let multiplier = 0.45;
        let OCCULAR_WIDTH = coordinates.eyeRight.position.x - coordinates.eyeLeft.position.x;
        let EYE_WIDTH = OCCULAR_WIDTH * multiplier;

        return [{
            'maskImage': uiImageElements.EYE_MASK,
            'center': coordinates.eyeLeft,
            'width': EYE_WIDTH,
            'height': EYE_WIDTH,
            'id': 'eyeLeft'
        }, {
            'maskImage': uiImageElements.EYE_MASK,
            'center': coordinates.eyeRight,
            'width': EYE_WIDTH,
            'height': EYE_WIDTH,
            'id': 'eyeRight'
        }, {
            'maskImage': uiImageElements.MOUTH_MASK,
            'center': coordinates.mouth,
            'width': OCCULAR_WIDTH,
            'height': OCCULAR_WIDTH,
            'id': 'mouth'
        }];

    },

    renderImageMasks: function (masks, workingCanvas, rotatedFaceCanvas) {

        // document.body.append(rotatedFaceCanvas);

        let ctx = workingCanvas.getContext('2d');
        let maskOutputs = [];

        // we loop through our masks, paint them, render the image they're masking,
        // then clear the canvas again. we need to clear the canvas each time to
        // stop the output masks overlapping each other

        for (let i in masks) {

            // determine how much to scale the masks
            let multiplier;
            if (masks[i].id == 'mouth') {
                multiplier = 2.5;
            } else {
                multiplier = 3.5;
            }

            let maskWidth = Math.round(masks[i].width * multiplier);
            let maskHeight = Math.round(masks[i].width * multiplier);

            ctx.clearRect(
                0,
                0,
                workingCanvas.width,
                workingCanvas.height); //clear the canvas

            ctx.drawImage(
                masks[i].maskImage,
                Math.round(masks[i].center.position.x - maskWidth / 2),
                Math.round(masks[i].center.position.y - maskWidth / 2),
                maskWidth,
                maskHeight);

            // drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight)

            // change composite mode to use that shape
            ctx.globalCompositeOperation = "source-in";

            // render our rotated face on the working canvas
            ctx.drawImage(rotatedFaceCanvas, 0, 0);

            // let maskCanvas = document.getElementById(masks[i].id);
            let maskOutput = document.createElement('canvas');
            // document.body.append(maskOutput);
            maskOutput.width = maskWidth;
            maskOutput.height = maskHeight;
            let maskCtx = maskOutput.getContext('2d');

            maskCtx.drawImage(
                workingCanvas,
                Math.round(masks[i].center.position.x - maskWidth / 2),
                Math.round(masks[i].center.position.y - maskWidth / 2),
                maskWidth,
                maskHeight,
                0,
                0,
                maskWidth,
                maskHeight);

            maskOutputs.push({
                id: masks[i].id,
                width: maskWidth,
                height: maskHeight,
                maskOutput: maskOutput
            });

            ctx.globalCompositeOperation = "destination-atop";

        };

        return maskOutputs;
    }

}