const _temp0 = require("url:./bobo.webp");
const _temp1 = require("url:./claude.webp");
const _temp2 = require("url:./ferris.webp");
const _temp3 = require("url:./kenji.webp");
const _temp4 = require("url:./knut.webp");
const _temp5 = require("url:./mabel.webp");
const _temp6 = require("url:./maverick.webp");
const _temp7 = require("url:./ogden.webp");
const _temp8 = require("url:./terrence.webp");
const _temp9 = require("url:./thadius.webp");
const _temp10 = require("url:./victor.webp");
module.exports = {
  "bobo": _temp0,
  "claude": _temp1,
  "ferris": _temp2,
  "kenji": _temp3,
  "knut": _temp4,
  "mabel": _temp5,
  "maverick": _temp6,
  "ogden": _temp7,
  "terrence": _temp8,
  "thadius": _temp9,
  "victor": _temp10
}