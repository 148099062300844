import CorrectImageOrientation from './correct-image-orientation.js';
import API from './api.js';
import preloadImages from './preload-images.js';
import extractHumanFeatures from './extract-human-features.js';
import renderPetswitch from './render-petswitch.js';
import edit from './edit.js';
import helpers from './helpers.js';
import DOM from './dom.js';
import share from './share.js';
import { app } from './firebase.js';
import { getAnalytics, logEvent } from "@firebase/analytics";

class Petswitch {

    constructor() {
        this.outputCanvas = document.querySelector('.output-canvas');
        this.uploadHumanFace = document.querySelector(".upload-human");
        this.uploadPetFace = document.querySelector(".upload-pet");
        this.pets = document.querySelectorAll('.pet');
        this.editHumanFace = document.querySelector(".edit-human");
        this.togglePetSelectionPanel = document.querySelector(".toggle-pets-button");
        this.overlay = document.querySelector(".overlay");
        this.share = document.querySelector(".share");
        this.analytics = getAnalytics(app);
        this.editMode = false;
        this.uiImageElements = {};
        this.humanFeatures = [];
        this.humanLandmarks;
        this.selectedPet;
    }

    init() {

        share.init(this.outputCanvas); // check compatibility for navigator.share

        // load up our image masks and UI elements for if a user needs to manually select features
        preloadImages.then((images) => {
            this.uploadHumanFace.disabled = false;
            images.forEach((image) => {
                this.uiImageElements[image.id] = image;
            })
        }).catch((e) => {
            console.log('image preload error', e);
        });

        this.uploadHumanFace.addEventListener('change', (event) => {

            logEvent(this.analytics,  `added_human`);

            // ensure our image is oriented correctly
            let correctImageOrientation = new CorrectImageOrientation();
            correctImageOrientation.checkImageFormat(event, (data) => {

                DOM.notice('hide');
                DOM.confirmAddImage(this.uploadHumanFace, 'human');
                helpers.setThumbnail('.upload-human-label', data.canvas, false);
                helpers.setThumbnail('.edit-human-button', data.canvas);

                this.correctedImageData = data;
                // initate our request to the cloud vision API immediately and store the promise
                this.humanLandmarks = API.getHumanFaceLandmarks(this.correctedImageData.corrected);
                this.uploadPetFace.disabled = false;

            });

        });

        this.pets.forEach((pet) => {

            pet.addEventListener('click', async (event) => {

                if (!this.humanLandmarks) {
                    DOM.notice('show');
                    return;
                }

                this.selectedPet = event.currentTarget.dataset.name;
                helpers.setThumbnail('.toggle-pets-button', event.currentTarget);
                DOM.addOutline(pet);
                logEvent(this.analytics, `selected_pet_${this.selectedPet}`);

                if (this.editMode) {
                    renderPetswitch.outputFinalImage(
                        this.selectedPet,
                        this.humanFeatures,
                        this.outputCanvas
                    );
                    DOM.petSelectionPanel('hide');
                    logEvent(this.analytics, `edited_pet_${this.selectedPet}`);
                    return;
                }

                DOM.startLoadingSequence();
                DOM.loader('show');

                // await the promise from the cloud vision API
                await this.humanLandmarks.then((humanLandmarks) => {
                    
                    // mask the eyes and mouth into canvas elements
                    extractHumanFeatures.beginRender(
                        humanLandmarks,
                        this.correctedImageData.canvas,
                        this.uiImageElements,
                        (humanFeatures) => {

                            this.editMode = true;
                            this.humanFeatures = humanFeatures;
                            // paint these masks onto our final canvas
                            renderPetswitch.outputFinalImage(
                                this.selectedPet,
                                this.humanFeatures,
                                this.outputCanvas
                            );

                        });

                }).catch(error => console.log('error getting human landmarks:', error));

            });
        });

        this.uploadPetFace.parentNode.addEventListener('click', (event) => {

            if (!this.humanLandmarks) {
                DOM.notice('show');    
                return;
            } 
            
        })

        this.uploadPetFace.addEventListener('change', async (event) => {

            this.selectedPet = 'USER_SELECTED';

            if (this.editMode) {
                
                edit.petFace(event,
                    'USER_SELECTED',
                    this.humanFeatures,
                    this.outputCanvas,
                    this.uiImageElements).then((result) => {
                        
                        this.correctedPetImageData = result.correctedPetImageData;
                        this.petLandmarks = result.petLandmarks;
                    });

                    logEvent(this.analytics, `edited_custom_pet`);

                return;
            }

            logEvent(this.analytics, `added_custom_pet`);
            DOM.startLoadingSequence();
            DOM.outputCanvas('hide');
            DOM.loader('show');
            DOM.clearPetSelection();

            await this.humanLandmarks.then((humanLandmarks) => {

                let correctImageOrientation = new CorrectImageOrientation();
                correctImageOrientation.checkImageFormat(event, (data) => {

                    helpers.setThumbnail('.toggle-pets-button', data.canvas);
                    // DOM.confirmAddImage(this.uploadPetFace, 'pet');

                    this.correctedPetImageData = data;
                    API.getPetFaceLandmarks({
                        image: this.correctedPetImageData.corrected,
                        imageWidth: this.correctedPetImageData.canvas.width,
                        imageHeight: this.correctedPetImageData.canvas.height

                    }).then((petLandmarks) => {
                        // mask the eyes and mouth into canvas elements

                        this.petLandmarks = petLandmarks;
                        
                        extractHumanFeatures.beginRender(
                            humanLandmarks,
                            this.correctedImageData.canvas,
                            this.uiImageElements,
                            (humanFeatures) => {

                                this.editMode = true;
                                this.humanFeatures = humanFeatures;
                                // paint these masks onto our final canvas
                                renderPetswitch.outputFinalImage(
                                    this.selectedPet,
                                    this.humanFeatures,
                                    this.outputCanvas,
                                    this.uiImageElements,
                                    this.correctedPetImageData.canvas,
                                    this.petLandmarks.prediction,
                                    (output) => {
                                        this.petLandmarks.userSelection = output;
                                    }
                                );

                            })

                    }).catch(error => console.log('error getting pet landmarks:', error));

                });

            }).catch(error => console.log('error getting human landmarks:', error));
        })

        this.editHumanFace.addEventListener('change', async (event) => {

            DOM.outputCanvas('hide');
            DOM.loader('show');

            logEvent(this.analytics, `edited_human`);

            edit.humanFace(event,
                this.uiImageElements,
                this.selectedPet,
                this.outputCanvas,
                this.correctedPetImageData,
                this.petLandmarks).then((humanFeatures) => {

                    DOM.outputCanvas('show');
                    DOM.loader('hide');

                    this.humanFeatures = humanFeatures;

                });

        });

        this.togglePetSelectionPanel.addEventListener('click', (event) => {

            DOM.petSelectionPanel('show');
            
        });

        this.overlay.addEventListener('click', (event) => {
            DOM.petSelectionPanel('hide');
        });

        this.share.addEventListener('click', (event) => {

            logEvent(this.analytics, `shared_petswitch`);
            share.canvas(this.outputCanvas);    
        });

    }

}

let petswitch = new Petswitch;
petswitch.init();