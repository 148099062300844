import eyeMask from '../assets/masks/eye-mask-sq.png?as=webp';
import mouthMask from '../assets/masks/mouth-mask-sq4.png?as=webp';
import eyeSelector from '../assets/selectors/eye-selector.png?as=webp';
import mouthSelector from '../assets/selectors/mouth-selector.png?as=webp';

const images = [{
    id: 'EYE_MASK',
    url: eyeMask
},
{
    id: 'MOUTH_MASK',
    url: mouthMask
},
{
    id: 'EYE_SELECTOR',
    url: eyeSelector
},
{
    id: 'MOUTH_SELECTOR',
    url: mouthSelector
}];

const getImage = imgObj => (
    new Promise((resolve, reject) => {
        const image = new Image()

        image.onload = e => {
            resolve(image)
        }

        image.onerror = e => {
            reject(Error("Network Error"))
        }

        image.src = imgObj.url
        image.id = imgObj.id
    })
)

const loadImages = images => (
    // console.log(images)    
    images.map(getImage)
)

export default Promise.all(loadImages(images));





