export default {
    bobo: {
        coordinates: {
            eyeLeft: {
                position:{
                    x: 425,
                    y: 461
                }
            },
            eyeRight: {
                position:{
                    x: 669,
                    y: 448
                }
            },
            mouth: {
                position:{
                    x: 555,
                    y: 587
                }
            },
        },
        occularWidth:180
    },
    claude: {
        coordinates: {
            eyeLeft: {
                position:{
                    x: 448,
                    y: 498
                }
            },
            eyeRight: {
                position:{
                    x: 575,
                    y: 500
                }
            },
            mouth: {
                position:{
                    x: 499,
                    y: 675
                }
            },
        },
        occularWidth:127
    },
    ferris: {
        coordinates: {
            eyeLeft: {
                position:{
                    x: 356,
                    y: 563
                }
            },
            eyeRight: {
                position:{
                    x: 712,
                    y: 575
                }
            },
            mouth: {
                position:{
                    x: 535,
                    y: 734
                }
            },
        },
        occularWidth:270
    },
    kenji: {
        coordinates: {
            eyeLeft: {
                position:{
                    x: 447,
                    y: 561
                }
            },
            eyeRight: {
                position:{
                    x: 606,
                    y: 561
                }
            },
            mouth: {
                position:{
                    x: 528,
                    y: 722
                }
            },
        },
        occularWidth:159
    },
    knut: {
        coordinates: {
            eyeLeft: {
                position:{
                    x: 546,
                    y: 264
                }
            },
            eyeRight: {
                position:{
                    x: 660,
                    y: 264
                }
            },
            mouth: {
                position:{
                    x: 600,
                    y: 372
                }
            },
        },
        occularWidth:114
    },
    mabel: {
        coordinates: {
            eyeLeft: {
                position:{
                    x: 324,
                    y: 630
                }
            },
            eyeRight: {
                position:{
                    x: 743,
                    y: 665
                }
            },
            mouth: {
                position:{
                    x: 482,
                    y: 1132
                }
            },
        },
        occularWidth:280
    },
    maverick: {
        coordinates: {
            eyeLeft: {
                position:{
                    x: 443,
                    y: 408
                }
            },
            eyeRight: {
                position:{
                    x: 650,
                    y: 392
                }
            },
            mouth: {
                position:{
                    x: 553,
                    y: 617
                }
            },
        },
        occularWidth:207
    },
    ogden: {
        coordinates: {
            eyeLeft: {
                position:{
                    x: 363,
                    y: 364
                }
            },
            eyeRight: {
                position:{
                    x: 515,
                    y: 355
                }
            },
            mouth: {
                position:{
                    x: 446,
                    y: 485
                }
            },
        },
        occularWidth:152
    },
    terrence: {
        coordinates: {
            eyeLeft: {
                position:{
                    x: 471,
                    y: 468
                }
            },
            eyeRight: {
                position:{
                    x: 625,
                    y: 456
                }
            },
            mouth: {
                position:{
                    x: 553,
                    y: 563
                }
            },
        },
        occularWidth:154
    },
    thadius: {
        coordinates: {
            eyeLeft: {
                position:{
                    x: 382,
                    y: 451
                }
            },
            eyeRight: {
                position:{
                    x: 764,
                    y: 450
                }
            },
            mouth: {
                position:{
                    x: 621,
                    y: 817
                }
            },
        },
        occularWidth:280
    },
    victor: {
        coordinates: {
            eyeLeft: {
                position:{
                    x: 429,
                    y: 535
                }
            },
            eyeRight: {
                position:{
                    x: 692,
                    y: 539
                }
            },
            mouth: {
                position:{
                    x: 553,
                    y: 781
                }
            },
        },
        occularWidth:263
    }
}