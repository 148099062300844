// import Hammer from '../lib/hammer-Konva.js';
import helpers from './helpers.js';
import Konva from 'konva';
import manualLandmarkInstructions from './manual-landmark-instructions.js';
import DOM from './dom.js';

export class ManualLandarkSelect {

    constructor() {
        this.confirmCoordinates = document.createElement('div');
        this.tick = document.createElement('div');
        this.stageParent = document.createElement('div');
        this.container = document.createElement('div');
        this.konva = {
            scale: 1,
            sceneWidth: 500,
            sceneHeight: 500
        };
    }

    startSelection(canvas, eyeSelector, mouthSelector, type, callback) {
        DOM.loader('hide');
        this.addElementsToDOM(type);
        this.initStage(canvas, eyeSelector, mouthSelector, type);
        this.fitStageIntoParentContainer();

        this.confirmCoordinates.addEventListener('click', (event) => {
            callback(this.returnCoordinates());
        });

        window.addEventListener('resize', (event) => {
            this.fitStageIntoParentContainer();
        });
    }

    addElementsToDOM(type) {

        let confirmText = document.createTextNode('Confirm');

        this.stageParent.setAttribute('id', `stage-parent-${type}`);
        this.container.setAttribute('id', `container-${type}`);
        this.confirmCoordinates.setAttribute('class', 'button');
        this.tick.setAttribute('class', 'tick');

        this.confirmCoordinates.appendChild(this.tick);
        this.confirmCoordinates.insertBefore(confirmText, this.tick);

        document.querySelector('.container').appendChild(this.stageParent);
        manualLandmarkInstructions.add(type, this.stageParent);

        this.stageParent.appendChild(this.container);
        this.stageParent.appendChild(this.confirmCoordinates);
    }

    fitStageIntoParentContainer() {
        // var container = document.querySelector('#stage-parent');

        // now we need to fit stage into parent container
        let stageParentWidth = this.stageParent.offsetWidth;

        // but we also make the full scene visible
        // so we need to scale all objects on canvas
        this.konva.scale = stageParentWidth / this.konva.sceneWidth;

        this.konva.stage.width(this.konva.sceneWidth * this.konva.scale);
        this.konva.stage.height(this.konva.sceneHeight * this.konva.scale);
        this.konva.stage.scale({ x: this.konva.scale, y: this.konva.scale });
    }

    initStage(canvas, eyeSelector, mouthSelector, type) {

        // user will need to manually select landmarks

        Konva.hitOnDragEnabled = true;

        let resized = helpers.calculateAspectRatioFit(canvas.width, canvas.height, 440, 440);

        this.konva.stage = new Konva.Stage({
            container: `container-${type}`,
            width: this.konva.sceneWidth,
            height: this.konva.sceneHeight,
        });

        this.konva.imageLayer = new Konva.Layer();
        let selectorsLayer = new Konva.Layer();

        this.konva.stage.add(this.konva.imageLayer);
        this.konva.stage.add(selectorsLayer);

        let originalAttrs = {
            x: this.konva.stage.width() / 2,
            y: (this.konva.stage.height() / 2) + 25,
            scaleX: 1,
            scaleY: 1,
            draggable: true,
            rotation: 0,
        };

        let group = new Konva.Group(originalAttrs);


        this.konva.imageLayer.add(group);

        this.konva.eyeLeft = new Konva.Image({
            image: eyeSelector,
            width: 70,
            height: 70,
            x: 160,
            y: 200,
            draggable: true
        });

        this.konva.eyeRight = new Konva.Image({
            image: eyeSelector,
            width: 70,
            height: 70,
            x: 270,
            y: 200,
            draggable: true
        });

        this.konva.mouth = new Konva.Image({
            image: mouthSelector,
            width: 150,
            height: 58,
            x: 175,
            y: 320,
            draggable: true
        });

        this.konva.image = new Konva.Image({
            image: canvas,
            width: resized.width,
            height: resized.height,
            offsetX: resized.width / 2,
            offsetY: resized.height / 2,
            cornerRadius: 5,
            shadowBlur: 10,
            shadowColor: 'grey',
        });

        group.add(this.konva.image)

        selectorsLayer.add(this.konva.eyeLeft);
        selectorsLayer.add(this.konva.eyeRight);
        selectorsLayer.add(this.konva.mouth);

        this.captureStageInteractions(group)
    }

    getDistance(p1, p2) {
        return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
    }

    getCenter(p1, p2) {
        return {
            x: (p1.x + p2.x) / 2,
            y: (p1.y + p2.y) / 2,
        };
    }

    captureStageInteractions(group) {

        this.konva.tr = new Konva.Transformer({
            nodes: [group],
            borderStroke: '#d99e33',
            anchorStroke: '#d99e33',
            rotateAnchorOffset: 30
        });
        this.konva.imageLayer.add(this.konva.tr);

        if (helpers.isTouchDevice()) {
            // now attach all possible events
            group.on('swipe', (ev) => {
                // text.text('swiping');
                group.to({
                    x: group.x() + ev.evt.gesture.deltaX,
                    y: group.y() + ev.evt.gesture.deltaY,
                });
            });
            this.konva.eyeLeft.on('swipe', (ev) => {
                // text.text('swiping');
                this.konva.eyeLeft.to({
                    x: this.konva.eyeLeft.x() + ev.evt.gesture.deltaX,
                    y: this.konva.eyeLeft.y() + ev.evt.gesture.deltaY,
                });
            });
            this.konva.eyeRight.on('swipe', (ev) => {
                // text.text('swiping');
                this.konva.eyeRight.to({
                    x: this.konva.eyeRight.x() + ev.evt.gesture.deltaX,
                    y: this.konva.eyeRight.y() + ev.evt.gesture.deltaY,
                });
            });
            this.konva.mouth.on('swipe', (ev) => {
                // text.text('swiping');
                this.konva.mouth.to({
                    x: this.konva.mouth.x() + ev.evt.gesture.deltaX,
                    y: this.konva.mouth.y() + ev.evt.gesture.deltaY,
                });
            });

            let lastCenter = null;
            let lastDist = 0;

            group.on('touchmove', (e) => {
                e.evt.preventDefault();
                let touch1 = e.evt.touches[0];
                let touch2 = e.evt.touches[1];

                if (touch1 && touch2) {

                    // if the stage was under Konva's drag&drop
                    // we need to stop it, and implement our own pan logic with two pointers
                    if (group.isDragging()) {
                        group.stopDrag();
                    }

                    let p1 = {
                        x: touch1.clientX,
                        y: touch1.clientY,
                    };
                    let p2 = {
                        x: touch2.clientX,
                        y: touch2.clientY,
                    };

                    if (!lastCenter) {
                        lastCenter = this.getCenter(p1, p2);
                        return;
                    }
                    let newCenter = this.getCenter(p1, p2);

                    let dist = this.getDistance(p1, p2);

                    if (!lastDist) {
                        lastDist = dist;
                    }

                    // local coordinates of center point
                    let pointTo = {
                        x: (newCenter.x - group.x()) / group.scaleX(),
                        y: (newCenter.y - group.y()) / group.scaleX(),
                    };

                    let scale = group.scaleX() * (dist / lastDist);

                    group.scaleX(scale);
                    group.scaleY(scale);

                    // calculate new position of the stage
                    let dx = newCenter.x - lastCenter.x;
                    let dy = newCenter.y - lastCenter.y;

                    var newPos = {
                        x: newCenter.x - pointTo.x * scale + dx,
                        y: newCenter.y - pointTo.y * scale + dy,
                    };

                    group.position(newPos);

                    lastDist = dist;
                    lastCenter = newCenter;
                }
            });

            group.on('touchend', () => {
                lastDist = 0;
                lastCenter = null;
            });

        } 
    }

    removeTransformControls(image, tr) {
        image.cornerRadius = 0;
        image.shadowBlur(0);
        image.shadowColor('transparent');
        if (tr != null) {
            tr.destroy();
        }
    }

    disposeContainer() {
        // remove editing elements
        this.konva.stage.destroy();
        document.querySelector('.container').removeChild(this.stageParent);
        window.removeEventListener('resize', (event) => {
            this.fitStageIntoParentContainer();
        });
    }

    returnCoordinates() {

        this.removeTransformControls(this.konva.image, this.konva.tr);

        let coordinates = {
            eyeLeft: {
                position: {
                    x: (this.konva.eyeLeft.x() + this.konva.eyeLeft.width() / 2) * 2 * this.konva.scale,
                    y: (this.konva.eyeLeft.y() + this.konva.eyeLeft.height() / 2) * 2 * this.konva.scale
                }
            },
            eyeRight: {
                position: {
                    x: (this.konva.eyeRight.x() + this.konva.eyeRight.width() / 2) * 2 * this.konva.scale,
                    y: (this.konva.eyeRight.y() + this.konva.eyeRight.height() / 2) * 2 * this.konva.scale
                }
            },
            mouth: {
                position: {
                    x: (this.konva.mouth.x() + this.konva.mouth.width() / 2) * 2 * this.konva.scale,
                    y: (this.konva.mouth.y() + this.konva.mouth.height() / 2) * 2 * this.konva.scale,
                }
            }
        };

        let rollAngle = helpers.getRollAngle(
            coordinates.eyeLeft.position.x,
            coordinates.eyeLeft.position.y,
            coordinates.eyeRight.position.x,
            coordinates.eyeRight.position.y);

        let output = {
            coordinates: coordinates,
            rollAngle: rollAngle,
            occularWidth: coordinates.eyeRight.position.x - coordinates.eyeLeft.position.x,
            canvas: this.konva.imageLayer.toCanvas({
                width: 500,
                height: 500,
                pixelRatio: 2
            })
        };

        this.disposeContainer();
        return output;

    }


}

module.exports = ManualLandarkSelect;