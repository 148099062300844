export default {

    init: function(outputCanvas) {

        if (navigator.canShare && navigator.canShare(outputCanvas)) {
            document.querySelector('a.download').classList.add('hidden');
        } else {
            document.querySelector('.share').classList.add('hidden');
        }

    },

    setDownloadPath: function (outputCanvas) {

        document.querySelector('a.download').setAttribute('href', outputCanvas.toDataURL('image/jpeg', 0.9));

    },

    canvas: async function (outputCanvas) {

        const dataUrl = outputCanvas.toDataURL('image/jpeg', 0.9);
        const blob = await (await fetch(dataUrl)).blob();
        const filesArray = [
            new File(
                [blob],
                'petswitch.jpeg',
                {
                    type: blob.type,
                    lastModified: new Date().getTime()
                }
            )
        ];
        const shareData = {
            files: filesArray,
        };
        try {
            await navigator.share(shareData);
          } catch(error) {
            console.log('cancelled share', error)
          }
    }

}